<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="create-customer-modal final-modal delivery-width">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">Delivery Location</span>
            </div>
            <div class="v-modal-body pr-0">
                <div class="v-modal-layout pt-3 pr-3" style="overflow-x: hidden !important;">
                    <div class="row pt-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group form-bottom flex-nowrap">
                                        <div class="group-setup">
                                            <input v-validate="'required'" :maxlength="castreet" v-model="customer.company_address.street"
                                                class="digi-form" type="text" name="street" id="edit_company_address_street"
                                                 />
                                            <span class="highlight digi-highlight"></span>
                                            <span class="bar digi-bar"></span>
                                            <label class="digi-label">Address 1<sup>★</sup></label>
                                            <span class="text-danger fs-13 mt-1" v-show="errors.has('street')">Street is Required</span>
                                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                style="margin-bottom: 0px!important;">
                                                {{castreet - customer.company_address.street.length}} /
                                                {{castreet}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group form-bottom flex-nowrap">
                                        <div class="group-setup">
                                            <input :maxlength="castreetnew" v-model="customer.company_address.street2"
                                                class="digi-form" type="text" name="streetnew"
                                                id="edit_company_address_street_new"  />
                                            <span class="highlight digi-highlight"></span>
                                            <span class="bar digi-bar"></span>
                                            <label class="digi-label">Address 2</label>
                                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                style="margin-bottom: 0px!important;">
                                                {{castreetnew - customer.company_address.street2.length}} /
                                                {{castreetnew}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-5">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <div class="group-setup">
                                        <input :maxlength="catownship"
                                            v-model="customer.company_address.township" class="digi-form"
                                            type="text" name="state" id="edit_company_address_township"
                                             />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">Township</label>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{catownship - customer.company_address.township.length}}
                                            / {{catownship}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-lg-4 col-md-4 col-sm-12 col-sm-12">
                                    <div class="group-setup">
                                        <input v-validate="'required'" :maxlength="cacity" v-model="customer.company_address.city"
                                            class="digi-form" type="text" name="city"
                                            id="edit_company_address_city" />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">City<sup>★</sup></label>
                                        <span class="text-danger fs-13 mt-1" v-show="errors.has('city')">City is Required</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{cacity - customer.company_address.city.length}} /
                                            {{cacity}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-lg-4 col-md-4 col-sm-12 col-sm-12">
                                    <div class="group-setup">
                                        <input :maxlength="castate" v-model="customer.company_address.state"
                                            class="digi-form" type="text" name="state"
                                            onkeypress="return (event.charCode > 64 &&
                                                                            event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                                             id="edit_company_address_state" />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">State</label>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                            style="margin-bottom: 0px!important;">
                                            {{castate - customer.company_address.state.length}} /
                                            {{castate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-5">
                                <div class="col-lg-4 col-lg-4 col-md-4 col-sm-12 col-sm-12 mt-1">
                                    <div class="group-setup">
                                        <input v-validate="'required'" :maxlength="cazipcode" v-model="customer.company_address.zipcode"
                                            class="digi-form" type="text" name="c_zipcode"
                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                            id="edit_company_address_zip_code" />
                                        <span class="highlight digi-highlight"></span>
                                        <span class="bar digi-bar"></span>
                                        <label class="digi-label">Zip Code<sup>★</sup></label>
                                        <span class="text-danger fs-13 mt-1" v-show="errors.has('c_zipcode')">Zip Code is Required</span>
                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1" style="margin-bottom: 0px!important;">{{cazipcode - customer.company_address.zipcode.length}} / {{cazipcode}}</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-12 selectlabels mt-2">
                                    <float-label label="Country ★">
                                        <select v-validate="'required'" v-model="customer.company_address.country" class="form-control"
                                            placeholder="Select Country" name="country"
                                            style="max-width: 100% !important;"
                                            id="edit_company_address_country">
                                            <option disabled selected hidden style="display:none;">
                                                Select Country</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Åland Island">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda
                                            </option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia, Plurinational State of">
                                                Bolivia, Plurinational State
                                                of
                                            </option>
                                            <option value="Bonaire, Sint Eustatius and Saba">
                                                Bonaire, Sint Eustatius and
                                                Saba
                                            </option>
                                            <option value="Bosnia and Herzegovina">Bosnia and
                                                Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">
                                                British Indian Ocean
                                                Territory
                                            </option>
                                            <option value="Brunei Darussalam">Brunei Darussalam
                                            </option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central
                                                African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island
                                            </option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling)
                                                Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, the Democratic Republic of the">
                                                Congo, the Democratic
                                                Republic of the
                                            </option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curaçao">Curaçao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic
                                            </option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea
                                            </option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland
                                                Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia
                                            </option>
                                            <option value="French Southern Territories">French
                                                Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and McDonald Islands">
                                                Heard Island and McDonald
                                                Islands
                                            </option>
                                            <option value="Holy See (Vatican City State)">Holy See
                                                (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic
                                                Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">
                                                Korea, Democratic
                                                People's Republic of
                                            </option>
                                            <option value="Korea, Republic of">Korea, Republic of
                                            </option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">
                                                Lao People's Democratic
                                                Republic
                                            </option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libya">Libya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, the Former Yugoslav Republic of">
                                                Macedonia, the
                                                Former Yugoslav Republic of
                                            </option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands
                                            </option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">
                                                Micronesia, Federated States
                                                of
                                            </option>
                                            <option value="Moldova, Republic of">Moldova, Republic
                                                of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern
                                                Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestine, State of">Palestine, State of
                                            </option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea
                                            </option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Réunion">Réunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation
                                            </option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Barthélemy">Saint Barthélemy
                                            </option>
                                            <option value="Saint Helena, Ascension and Tristan da Cunha">
                                                Saint Helena,
                                                Ascension and Tristan da Cunha
                                            </option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and
                                                Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Martin (French part)">Saint Martin
                                                (French part)</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre
                                                and Miquelon</option>
                                            <option value="Saint Vincent and the Grenadines">
                                                Saint Vincent and the
                                                Grenadines
                                            </option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and
                                                Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Sint Maarten (Dutch part)">Sint Maarten
                                                (Dutch part)</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands
                                            </option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">
                                                South Georgia
                                                and the South Sandwich Islands
                                            </option>
                                            <option value="South Sudan">South Sudan</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan
                                                Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab
                                                Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan,
                                                Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania,
                                                United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago
                                            </option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and
                                                Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab
                                                Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">
                                                United States Minor
                                                Outlying Islands
                                            </option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela, Bolivarian Republic of">
                                                Venezuela, Bolivarian
                                                Republic of
                                            </option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands,
                                                British</option>
                                            <option value="Virgin Islands, U.S">Virgin Islands,
                                                U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna
                                            </option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </select>
                                    </float-label>
                                    <span class="text-danger fs-13" v-show="errors.has('country')">Country is
                                        Required</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-modal-dialog-actions modal-bg text-center pt-5 pb-5">
                <a
                    class="btn btn-link btn-smm"
                    style="text-transform: uppercase !important;font-size: 12px !important;"
                    @click="hideLocation()"
                    >CANCEL</a
                >
                <button type="submit" class="btn btn-secondary btn-smm" @click="saveLocation()">SAVE</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import global from "../global";
    import customer from "../mixins/customer"
    export default ({
        props:['modal_name','customer_id','token'],
        data() {
            return {
                customer: {
                    company_address: {
                        street: '',
                        street2: '',
                        township: '',
                        city: '',
                        state: '',
                        zipcode: '',
                        country: '',
                    },
                    company_tax_code: '',
                },
                castreet: 42,
                castreetnew: 42,
                cacity: 21,
                catownship: 21,
                cazipcode: 12,
                comapnaytaxcode: 20,
                castate: 21

            }
        },
        mixins:[customer],
        methods:{
            hideLocation() {
                this.$emit('hide-delivery-location')
            },
            saveLocation(){
                this.$validator.validateAll().then(async result => {
        		    if(result){
                        let params = {
                            "_id": this.customer_id,
                            "b2c_flow": true,
                            "address": {
                                "street": this.customer.company_address.street,
                                "street2": this.customer.company_address.street2,
                                "city": this.customer.company_address.city,
                                "state": this.customer.company_address.state,
                                "country": this.customer.company_address.country,
                                "zipcode": this.customer.company_address.zipcode,
                                "township": this.customer.company_address.township
                            }
                        }
                        fetch(global.POS_API+'/customers/update_customer',{
                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': this.token
                            }),
                            body: JSON.stringify(params)
                        }).then(response => {
                            if (response.status < 200 || response.status >= 400) {
                                throw 'Error customer';
                            }
                            console.log('Updated Customer');
                            localStorage.setItem("delivery_location",JSON.stringify(params))
                            this.$modal.hide(this.modal_name)
                            this.$emit("saveLocation")
                        }).catch(error => {
                            console.error(error);
                        })
                    }
                })
            }
        }
    })
</script>

<style scoped>
    .final-modal .inputContact {
        border-bottom: solid 1px #a9aaad !important;
    }

    .final-modal .digi-form {
        border-bottom: solid 1px #a9aaad !important;
        background: transparent;
    }

    .final-modal .form-control {
        border-bottom: 1px solid #a9aaad !important;
    }

    .final-modal .vue-tel-input {
        border: 1px solid transparent !important;
        border-bottom: 1px solid #a9aaad !important;
    }

    .final-modal .vue-tel-input input {
        background-color: transparent;
    }

    .final-modal .v-select .dropdown-toggle {
        border-bottom: solid 1px #a9aaad !important;
    }

    .v-modal-layout {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .heading-details {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: 0.7px;
        text-align: left;
        color: #00448b;
        padding-top: 15px;
    }

    .group-setup {
        position: relative;
        margin-bottom: 20px;
    }

    .select-map-span {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
        margin-left: 10px;
    }

    .heading-details-black {
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        color: #303031;
        padding-top: 20px;
        margin-bottom: 4px;
    }

    .edit-customer-modal .dropdown.v-select input {
        position: relative !important;
    }

    .v-select.likes-vselect.searchable .dropdown-toggle {
        display: flex !important;
    }

    .final-modal .inputContact {
        border-bottom: solid 1px #a9aaad !important;
        background-color: transparent;
    }

    .final-modal .digi-form.modal-bg {
        border-bottom: solid 1px #a9aaad !important;
        background-color: transparent;
    }

    .final-modal .form-control {
        border-bottom: 1px solid #a9aaad !important;
    }

    .final-modal .vue-tel-input {
        border: 1px solid transparent !important;
        border-bottom: 1px solid #a9aaad !important;
        background-color: transparent;
    }

    .final-modal .vue-tel-input input {
        background-color: transparent;
    }

    .final-modal .v-select .dropdown-toggle {
        border-bottom: solid 1px #a9aaad !important;
    }
</style>